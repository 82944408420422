/* You can add global styles to this file, and also import other style files */

@import 'moffi-theme.scss';
@import 'colors.scss';
@import 'animations.scss';
@import "@coreui/coreui/scss/coreui";

*, *::before, *::after {
  box-sizing: unset;
}

html,
body {
  font-family: 'Satoshi', sans-serif !important;
  height: 100%;
  --mat-menu-container-shape: 0.7rem;
  --mat-toolbar-standard-height: 5rem;
  --mat-toolbar-mobile-height: 4rem;

  // Coreui
  --cui-border-width: 0px;

  // Material
  --mat-option-selected-state-label-text-color: $secondary-color;

  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {
    background-color: $secondary-color !important;
  }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
    caret-color: #03225c !important;
  }
  .mat-mdc-tab-body-content {
    overflow: hidden;
  }

  // Home Page
  --home-panel-margin-top: 10rem;
  --home-panel-gap: 2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div,
span,
p,
button {
  font-family: 'Satoshi', sans-serif !important;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

a {
  color: $secondary-color;
}

.a-block {
  display: block;
  text-decoration: none;
}

img {
  max-width: 100%;
  vertical-align: unset;
}

ul {
  list-style-type: none;
  padding: 0rem;
}

li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}
li .mat-icon {
  margin-right: 1rem;
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4 {
  font-family: 'Satoshi', sans-serif;
}

h1 {
  color: $primary-color;
  font-size: max(40px, min(60px, calc(6vw)));
  font-weight: 700;
  margin: unset;
  font-family: 'Satoshi-Medium', sans-serif;
  line-height: 8vh !important;
}

h2 {
  color: $primary-color;
  font-size: max(35px, min(35px, calc(6vw)));
  font-weight: 700;
  line-height: normal;
  margin: unset;
  font-family: 'Satoshi-Medium', sans-serif;
}

h1 > span {
  font-family: 'Satoshi-Medium', sans-serif !important;
  font-size: max(40px, min(60px, calc(6vw)));
  line-height: 8vh !important;
}

h2 > span {
  font-family: 'Satoshi-Medium', sans-serif !important;
  font-size: max(35px, min(35px, 6vw));
}

h1 > span > span {
  font-family: 'Satoshi-Medium', sans-serif !important;
  font-size: max(40px, min(60px, calc(6vw)));
  line-height: 8vh !important;
}

h3 {
  color: $primary-color;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  margin: unset;
}

h4 {
  color: $third-color;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  margin: unset;
  margin-top: 1rem;
}

// Taille des images pour petit écran
@media screen and (max-width: 1150px) {
  img {
    max-width: 95%;
    height: auto;
  }
  body {
    --mat-toolbar-standard-height: 4rem;
    --home-panel-margin-top: 4rem;
    --home-panel-gap: 1rem;
  }

  h1 {
    line-height: 8vh !important;
  }

  h1 > span,
  h2 > span,
  span > span {
    line-height: 6vh !important;
  }
}

.error {
  font-size: 14px;
  font-weight: 400;
  color: $error-color;
}

// Font size
.fs-29 {
  font-size: 29px;
}

.fs-21 {
  font-size: 21px;
}

.fs-20 {
  font-size: 20px;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
}

// Font weight
.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-550 {
  font-weight: 550;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

// Font style
.f-style-normal {
  font-style: normal;
}

.f-style-italic {
  font-style: italic;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: 500px !important;
}
.mat-elevation-z8,
.mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 8px 35px 0px #7285d840;
}

.menu-open .cdk-overlay-backdrop {
  display: none !important;
}

.mat-icon {
  overflow: visible !important;
}

.mat-mdc-snack-bar-container {
  padding: 1rem;
  margin-top: 5rem !important;
  margin-right: 2rem !important;
  --mdc-snackbar-container-color: white;
  --mdc-snackbar-supporting-text-font: 'Satoshi', sans-serif !important;
  --mdc-snackbar-supporting-text-size: 1rem;
  --mdc-snackbar-supporting-text-color: $primary-color;
  --mdc-snackbar-container-shape: 0.5rem;
}

@media screen and (max-width: 1150px) {
  .mat-mdc-snack-bar-container {
    margin-right: 0rem !important;
  }
}

.mdc-snackbar__label {
  white-space: pre-line;
}

div.mat-expansion-panel-body {
  padding: 0rem;
}

.primary-color {
  color: $primary-color !important;
}
.secondary-color {
  color: $secondary-color !important;
}
.third-color {
  color: $third-color !important;
}

.dark-color {
  color: $dark-color !important;
}

.purple {
  color: $purple;
}

.blue {
  color: $blue;
}

.cyan {
  color: $cyan;
}
.pink {
  color: $pink;
}

.green {
  color: $green;
}
